$hyoo_toys_details $mol_page
	toy $hyoo_toys_toy
	tools /
		<= Close $mol_link
			arg *
				toy null
			sub /
				<= Close_icon $mol_icon_cross
	body /
		<= Main $mol_row
			sub /
				<= Image $mol_view
					sub /
						<= Photo $mol_image
							uri <= photo_uri \
							style *
								filter <= photo_filter \
				<= Info $mol_card
					sub /
						<= Buy_link $mol_link
							uri \http://boosty.to/hyoo
							sub /
								<= Buy_button $mol_button_major
									sub /
										<= buy @ \Buy for 
										<= Price $mol_cost
											value <= price $mol_unit_money
												valueOf 0
						<= Parameters $mol_view
							sub /
								<= Type $mol_view
									sub /
										<= type \
								<= Size $mol_view
									sub /
										<= size_prefix @ \Size: 
										<= size \
								<= Reviews $mol_view
									sub /
										<= reviews_prefix @ \Reviews: 
										<= reviews 0
		<= Description $mol_filler
