$mol_card $mol_list
	attr *
		^
		mol_card_status_type <= status \
	rows /$mol_view
		<= Content $mol_view
			sub <= content /$mol_view_content
				<= title
		<= Status $mol_view
			minimal_height 30
			sub /
				<= status_text <= status \
